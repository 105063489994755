import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/',
        component: () => import('@/layout/LayoutSalesTermCondition.vue'),
        redirect: '/pengaturan/term-condition', // Change the redirect to the desired path
        children: [
            {
                path: 'pengaturan/term-condition',
                name: 'TermCondition',
                component: () => import('@/views/TermsConditions.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
